dmx.Component('calendar', {

    constructor: function(node, parent) {
        this.onDateClick = this.onDateClick.bind(this);
        this.onEventClick = this.onEventClick.bind(this);
        this.onEventMouseEnter = this.onEventMouseEnter.bind(this);
        this.onEventMouseLeave = this.onEventMouseLeave.bind(this);
        this.onEventDrop = this.onEventDrop.bind(this);
        this.onEventResize = this.onEventResize.bind(this);
        this.onEventRender = this.onEventRender.bind(this);
        this.onEventDestroy = this.onEventDestroy.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.parseSource = this.parseSource.bind(this);

        dmx.BaseComponent.call(this, node, parent);
    },

    attributes: {
        'timezone': { // moment-timezone or luxon plugin for named timezones
            type: String,
            default: 'local' // "local", "UTC"
        },

        'date': {
            type: String,
            default: null // ISO8601 format
        },

        'locale': { // locales include needed
            type: String,
            default: null
        },

        'height': { // height of entire calendar
            type: String,
            default: null // interger, "parent", "auto"
        },

        'aspect-ratio': { // width-to-height aspect ratio
            type: Number,
            default: 1.35
        },

        'view': { // dayGrid, timeGrid, list plugin
            type: String,
            default: null // name of view like "dayGridMonth"
        },

        'views': {
            type: Array,
            default: []
        },

        'theme': { // bootstrap plugin
            type: String,
            default: 'standard' // "standard", "bootstrap"
        },

        'hide-non-current-dates': { // dayGrid plugin (dayGridMonth only)
            type: Boolean,
            default: false
        },

        'selectable': { // interaction plugin
            type: Boolean,
            default: false
        },

        /* will use custom parser for this
        'constraint': { // interaction plugin
            type: [String, Object], // https://fullcalendar.io/docs/selectConstraint
            default: null
        },
        */

        'editable': { // interaction plugin
            type: Boolean,
            default: null
        },

        'no-event-overlap': {
            type: Boolean,
            default: false
        },

        'business-hours': {
            type: Boolean, // https://fullcalendar.io/docs/businessHours
            default: false
        },

        'google-calendar-api-key': {
            type: String,
            default: null
        },

        'no-fixed-week-count': {
            type: Boolean, // https://fullcalendar.io/docs/fixedWeekCount
            default: false
        },

        'event-order': {
            type: String, // https://fullcalendar.io/docs/eventOrder
            default: 'start,-duration,allDay,title'
        },

        'event-limit': {
            type: [Boolean, Number], // https://fullcalendar.io/docs/eventLimit
            default: false
        },

        'now-indicator': {
            type: Boolean, // https://fullcalendar.io/docs/nowIndicator
            default: false
        },

        'bs4-tooltip': {
            type: Boolean,
            default: false
        },

        'bs4-tooltip-placement': {
            type: String, // expression
            default: '"top"'
        },

        'bs4-tooltip-title': {
            type: String, // expression
            default: 'event.extendedProps.description || event.title'
        },

        'views-options': {
            type: Object, // https://fullcalendar.io/docs/view-specific-options
            default: {}
        }
    },

    methods: {
        gotoDate: function(date) {
            this.calendar.gotoDate(date);
        },

        updateSize: function() {
            this.calendar.updateSize();
        },

        prev: function() {
            this.calendar.prev();
        },

        next: function() {
            this.calendar.next();
        },

        prevYear: function() {
            this.calendar.prevYear();
        },

        nextYear: function() {
            this.calendar.nextYear();
        },

        today: function() {
            this.calendar.today();
        }
    },

    events: {
        dateclick: MouseEvent, // interaction plugin
        eventclick: MouseEvent,
        eventmouseenter: MouseEvent,
        eventmouseleave: MouseEvent,
        eventdrop: Event,
        eventresize: Event,
        select: Event
    },

    onDateClick: function(info) {
        var cancelled  = !this.dispatchEvent('dateclick', info.jsEvent, {
            date: info.dateStr,
            allDay: info.allDay
        });

        if (cancelled) {
            info.jsEvent.preventDefault();
        }
    },

    onEventClick: function(info) {
        var cancelled  = !this.dispatchEvent('eventclick', info.jsEvent, {
            event: this.getEventProps(info.event)
        });

        if (cancelled) {
            info.jsEvent.preventDefault();
        }
    },

    onEventMouseEnter: function(info) {
        this.dispatchEvent('eventmouseenter', info.jsEvent, {
            event: this.getEventProps(info.event)
        });
    },

    onEventMouseLeave: function(info) {
        this.dispatchEvent('eventmouseleave', info.jsEvent, {
            event: this.getEventProps(info.event)
        });
    },

    onEventDrop: function(info) {
        this.dispatchEvent('eventdrop', info.jsEvent, {
            event: this.getEventProps(info.event)
        });
    },

    onEventResize: function(info) {
        this.dispatchEvent('eventresize', info.jsEvent, {
            event: this.getEventProps(info.event)
        });
    },

    onEventRender: function(info) {
        var scope = new dmx.DataScope({
            event: this.getEventProps(info.event)
        }, this);

        if (info.event.source && info.event.source.internalEventSource.extendedProps && info.event.source.internalEventSource.extendedProps.bs4Tooltip) {
            $(info.el).tooltip({
                placement: dmx.parse(info.event.source.internalEventSource.extendedProps.bs4TooltipPlacement, scope) || 'top',
                title: dmx.parse(info.event.source.internalEventSource.extendedProps.bs4TooltipTitle, scope) || ''
            });
        } else if (this.props['bs4-tooltip']) {
            $(info.el).tooltip({
                placement: dmx.parse(this.props['bs4-tooltip-placement'], scope) || 'top',
                title: dmx.parse(this.props['bs4-tooltip-title'], scope) || ''
            });
        }
    },

    onEventDestroy: function(info) {
        if (this.props['bs4-tooltip']) {
            $(info.el).tooltip('dispose');
        }
    },

    onSelect: function(info) {
        this.dispatchEvent('select', info.jsEvent, {
            start: info.startStr,
            end: info.endStr,
            allDay: info.allDay
        });
    },

    parseSource: function(child) {
        if (child instanceof dmx.Component('calendar-source-base')) {
            child.calendar = this.calendar;
            child.register();
        }
    },

    $parseAttributes: function(node) {
        var self = this;

        dmx.BaseComponent.prototype.$parseAttributes.call(this, node);

        // parse dmx-business-hours
        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (attr.name == 'business-hours') {
                self.$addBinding(attr.value, function(value) {
                    if (value != null) {
                        if (!Array.isArray(this.props['business-hours'])) {
                            self.props['business-hours'] = [];
                        }

                        self.props['business-hours'].push(value);
                    }
                });
            }
        });

        // views options custom parse
        this.parseAttribute(node, 'views-options').forEach(function(attr) {
            self.$addBinding(attr.value, function(value) {
                self.props['views-options'][this.toCamelCase(attr.argument)] = value || {};
            });
        });

        // constraint custom parse
        this.parseAttribute(node, 'constraint').forEach(function(attr) {
            if (attr.modifiers['business-hours']) {
                // special case for businessHours modifier
                self.props.constraint = 'businessHours';
            } else {
                if (attr.binding) {
                    // dynamic attribute
                    self.$addBinding(attr.value, function(value) {
                        self.props.constraint = value;
                    });
                } else {
                    // static attribute
                    self.props.constraint = attr.value;
                }
            }

            // we need to remove the attribute so that the default parser doesn't parse it
            node.removeAttribute(attr.fullName);
        });
    },

    render: function(node) {
        // detect installed plugins
        var plugins = ['interaction', 'dayGrid', 'timeGrid', 'list', 'bootstrap', 'googleCalendar'].filter(function(name) {
            return !!window['FullCalendar' + name[0].toUpperCase() + name.slice(1)];
        });

        this.calendar = new FullCalendar.Calendar(node, {
            plugins: plugins,
            googleCalendarApiKey: this.props['google-calendar-api-key'],
            timeZone: this.props.timezone,
            defaultDate: this.props.date,
            defaultView: this.props.view,
            locale: this.props.locale,
            height: this.props.height,
            aspectRatio: this.props['aspect-ratio'],
            themeSystem: this.props.theme,
            selectable: this.props.selectable,
            selectConstraint: this.props.constraint,
            editable: this.props.editable,
            showNonCurrentDates: !this.props['hide-non-current-dates'],
            eventOverlap: !this.props['no-event-overlap'],
            businessHours: this.props['business-hours'],
            fixedWeekCount: !this.props['no-fixed-week-count'],
            eventOrder: this.props['event-order'],
            eventLimit: this.props['event-limit'],
            nowIndicator: this.props['now-indicator'],
            dateClick: this.onDateClick,
            eventClick: this.onEventClick,
            eventMouseEnter: this.onEventMouseEnter,
            eventMouseLeave: this.onEventMouseLeave,
            eventDrop: this.onEventDrop,
            eventResize: this.onEventResize,
            eventRender: this.onEventRender,
            eventDestroy: this.onEventDestroy,
            select: this.onSelect,
            views: this.props['views-options'],
            header: {
                left: 'today prev,next',
                center: 'title',
                right: this.props.views.toString()
            }
        });

        this.$parse();

        this.calendar.render();
    },

    update: function(props) {
        if (props.view != this.props.view) {
            this.calendar.changeView(this.props.view || 'dayGridMonth');
        }

        if (props.date != this.props.date) {
            this.calendar.gotoDate(this.props.date);
        }

        if (props.timezone != this.props.timezone) {
            this.calendar.setOption('timeZone', this.props.timezone);
        }

        if (props.locale != this.props.locale) {
            this.calendar.setOption('locale', this.props.locale);
        }

        if (props.theme != this.props.theme) {
            this.calendar.setOption('themeSystem', this.props.theme);
        }

        if (props['hide-non-current-dates'] != this.props['hide-non-current-dates']) {
            this.calendar.setOption('showNonCurrentDates', !this.props['hide-non-current-dates']);
        }

        if (props.selectable != this.props.selectable) {
            this.calendar.setOption('selectable', this.props.selectable);
        }

        if (props.editable != this.props.editable) {
            this.calendar.setOption('editable', this.props.editable);
        }

        if (props['no-event-overlap'] != this.props['no-event-overlap']) {
            this.calendar.setOption('eventOverlap', !this.props['no-event-overlap']);
        }

        if (!dmx.equal(props['business-hours'], this.props['business-hours'])) {
            this.calendar.setOption('businessHours', this.props['business-hours']);
        }

        if (!dmx.equal(props.constraint, this.props.constraint)) {
            this.calendar.setOption('selectConstraint', this.props.constraint);
        }

        if (props['google-calendar-api-key'] != this.props['google-calendar-api-key']) {
            this.calendar.setOption('googleCalendarApiKey', this.props['google-calendar-api-key']);
        }

        if (props['no-fixed-week-count'] != this.props['no-fixed-week-count']) {
            this.calendar.setOption('fixedWeekCount', !this.props['no-fixed-week-count']);
        }

        if (props['event-order'] != this.props['event-order']) {
            this.calendar.setOption('eventOrder', this.props['event-order']);
        }

        if (props['event-limit'] != this.props['event-limit']) {
            this.calendar.setOption('eventLimit', this.props['event-limit']);
        }

        if (props['now-indicator'] != this.props['now-indicator']) {
            this.calendar.setOption('nowIndicator', this.props['now-indicator']);
        }

        if (!dmx.equal(props['views-options'], this.props['views-options'])) {
            this.calendar.setOption('views', this.props['views-options']);
        }
    },

    beforeDestroy: function() {
        this.calendar.destroy();
    },

    getEventProps: function(event) {
        return {
            id: event.id,
            start: event.start && event.start.toISOString(),
            end: event.end && event.end.toISOString(),
            allDay: event.allDay,
            title: event.title,
            extendedProps: event.extendedProps
        };
    },

    // special parse required for constraint attribute
    // supports static/dynamic attributes
    parseAttribute: function(node, attrName) {
        var attributes = [];
        var re = new RegExp('^(dmx\\-bind:)?' + attrName.replace(/[-[\]{}()*+?.,\^$|#\s]/g, '\\$&'), 'i');

        if (node.nodeType == 1) {
            for (var i = 0; i < node.attributes.length; i++) {
                var attribute = node.attributes[i];

                if (attribute && attribute.specified && re.test(attribute.name)) {
                    var name = attribute.name;
                    var argument = null;
                    var modifiers = {};

                    name = name.replace(/^dmx\-bind:/i, '');

                    name.split('.').forEach(function(part, i) {
                        if (i === 0) {
                            name = part;
                        } else {
                            var pos = part.indexOf(':');
                            if (pos > 0) {
                                modifiers[part.substr(0, pos)] = part.substr(pos + 1);
                            } else {
                                modifiers[part] = true;
                            }
                        }
                    });

                    var pos = name.indexOf(':');
                    if (pos > 0) {
                        argument = name.substr(pos + 1);
                        name = name.substr(0, pos);
                    }

                    attributes.push({
                        name: name,
                        binding: attribute.name.indexOf('dmx-bind:') === 0,
                        fullName: attribute.name,
                        value: attribute.value,
                        argument: argument,
                        modifiers: modifiers
                    });
                }
            }
        }

        return attributes;
    },

    toCamelCase: function(str) {
        return str.replace(/-(\w)/g, function(a, b) {
            return b.toUpperCase();
        });
    }

});